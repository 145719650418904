.fiftyFifty {
    display: flex;
}
.fifOne {
    width: 30%;
    height: 200px;
}
.fifTwo {
    width: 70%;
    text-overflow: unset;
}
.tc {
    text-align: center;
}
/* .textOF {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
} */
/* tr {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px; 
    max-height: 80vh; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
} */
.select {
    width: 100%;
}
.select option {
    text-align: center;
}
.limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

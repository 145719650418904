.flow {
    text-align: right !important;
    direction: ltr !important;
}
.bd {
    border: red solid 3px;
}
::-webkit-scrollbar {
    background: rgba(255, 255, 255, 0);
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #ce3233;
}

::selection {
    background-color: #ce3233;
    color: white;
}
.mobshow {
    visibility: hidden;
}
@media (max-width: 990px) {
    .mobshow {
        visibility: visible;
    }
}

.ExtraNav {
    background-color: white;
    z-index: 99999;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.ExtraNav h3 {
    font-size: 20px;
    margin: auto 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ExtraNav h3:hover {
    color: #ce3233;
}

.logo22 {
    display: none;
}

@media (max-width: 765px) {
    .logo {
        display: none;
    }
    .logo22 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        /* border: #ce3233 solid; */
    }
}

.more {
    cursor: pointer;
}

.more:hover {
    color: #ce3233;
}
